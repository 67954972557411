<template>
  <v-row>
    <template v-if="permisos.includes('contrate_hotels:edit') || permisos.includes('*')">
      <template v-if="!isLoadingContratesHotels">
        <v-col
          cols="12"
        >
          <b>
            {{ $t('dashboard.contrateHotels') }}
          </b><br />
          {{ $t('dashboard.contrateHotels2') }}
        </v-col>
        <v-col
          v-for="(contrate, index) in contratesHotels"
          :key="index"
          cols="6"
        >
          <VencimientoHotels
            :user="user"
            :contrate="contrate"
          />
        </v-col>
      </template>
      <template v-else>
        <v-col
          v-for="i in 2"
          :key="i"
          cols="6"
        >
          <v-skeleton-loader
            type="table-heading, list-item-two-line, table-tfoot, actions"
          ></v-skeleton-loader>
        </v-col>
      </template>
    </template>
  </v-row>
</template>

<script>

import VencimientoHotels from './contrates/VencimientoHotels.vue'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    VencimientoHotels,
  },
  data() {
    return {
      permisos: localStorage.getItem('permisos_auth'),
      isLoadingContratesHotels: true,
      contratesHotels: [],
      user: {},
    }
  },
  created() {
    this.profile()
  },
  methods: {
    profile() {
      this.axios
        .get(`${process.env.VUE_APP_API_URL}profile`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
        // eslint-disable-next-line no-empty
          if (response.data.success === false) {
          }
          this.user = response.data.data
        })
      // eslint-disable-next-line no-return-assign
        .finally(() => {
          if (localStorage.getItem('pendients-contrates-hotels')) {
            this.contratesHotels = JSON.parse(localStorage.getItem('pendients-contrates-hotels'))
            this.isLoadingContratesHotels = false
          } else {
            this.isLoadingContratesHotels = false
          }
        })
    },
  },
}
</script>
