<template>
  <v-card
    style="cursor: pointer;"
    @click="goContrate()"
  >
    <v-card-title class="align-start">
      <span class="font-weight-semibold">{{ contrate.identificador }}</span>
      <!--<v-spacer></v-spacer>
      <v-btn
        icon
        small
        class="me-n3 mt-n2"
        @click="goContrate()"
      >
        <v-icon>
          mdi-arrow-right-bold-outline
        </v-icon>
      </v-btn> -->
    </v-card-title>

    <v-card-subtitle class="mb-8 mt-n5">
      <v-row>
        <v-col cols="12">
          <span class="font-weight-semibold text--primary me-1">
            {{ $t('lbl.proveedor') }}:
          </span>
          <span v-if="user.nivel <= 0">
            <span v-if="contrate.operador === 1111">
              <span v-if="contrate.proveedor_id != null">
                {{ contrate.proveedor }}
              </span>
            </span>
            <span v-else>
              {{ contrate.operador }}
            </span>
          </span>
          <span v-else>
            <span v-if="contrate.proveedor_id != null">
              {{ contrate.proveedor }}
            </span>
          </span>
          <br />
          <span class="font-weight-semibold text--primary me-1">
            {{ $t('lbl.operador') }}:
          </span>
          <span v-if="contrate.operators.length === 1">
            {{ contrate.operators[0] }}
          </span>
          <span v-if="contrate.operators.length > 1">
            <v-tooltip
              top
              color="primary"
            >
              <template v-slot:activator="{ on, attrs }">
                <span
                  style="cursor: pointer;"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ $t('dashboard.varios') }}
                </span>
              </template>
              <span>
                <span
                  v-for="(operator, indO) in contrate.operators"
                  :key="indO"
                > {{ operator }} <br /> </span>
              </span>
            </v-tooltip>
          </span>
          <br />
          <span class="font-weight-semibold text--primary me-1">
            {{ $t('dashboard.source') }}:
          </span>
          <span v-if="contrate.source.length === 0">
            {{ $t('dashboard.direct') }}
          </span>
          <span v-else>
            <span
              v-for="(sss, indS) in contrate.source"
              :key="indS"
            >
              {{ getSourceByOne(contrate) }}
            </span>
          </span>
        </v-col>
      </v-row>
    </v-card-subtitle>

    <v-card-text>
      <v-row>
        <!--SIN TARIFARIOS-->
        <v-col
          v-if="contrate.cant_sin_tarifarios > 0"
          cols="6"
          class="d-flex align-center"
        >
          <v-avatar
            size="44"
            color="warning"
            rounded
            class="elevation-1"
          >
            <v-icon
              dark
              color="white"
              size="30"
            >
              mdi-currency-usd-off
            </v-icon>
          </v-avatar>
          <div class="ms-3">
            <p class="text-xs mb-0">
              {{ $t('dashboard.sinTarifario') }}
            </p>
            <h3 class="text-xl font-weight-semibold">
              {{ contrate.cant_sin_tarifarios }}
            </h3>
          </div>
        </v-col>
        <!--SIN TARIFAS-->
        <v-col
          v-if="contrate.cant_sin_tarifas > 0"
          cols="6"
          class="d-flex align-center"
        >
          <v-avatar
            size="44"
            color="warning"
            rounded
            class="elevation-1"
          >
            <v-icon
              dark
              color="white"
              size="30"
            >
              mdi-chart-timeline-variant
            </v-icon>
          </v-avatar>
          <div class="ms-3">
            <p class="text-xs mb-0">
              {{ $t('dashboard.tarifasUndefined') }}
            </p>
            <h3 class="text-xl font-weight-semibold">
              {{ contrate.cant_sin_tarifas }}
            </h3>
          </div>
        </v-col>
        <!--VENCEN EN 3 MESES-->
        <v-col
          v-if="contrate.cant_vencen_3 > 0"
          cols="6"
          class="d-flex align-center"
        >
          <v-avatar
            size="44"
            color="info"
            rounded
            class="elevation-1"
          >
            <v-icon
              dark
              color="white"
              size="30"
            >
              mdi-currency-usd
            </v-icon>
          </v-avatar>
          <div class="ms-3">
            <p class="text-xs mb-0">
              {{ $t('dashboard.venceIn', { n: 3 }) }}
            </p>
            <h3 class="text-xl font-weight-semibold">
              {{ contrate.cant_vencen_3 }}
            </h3>
          </div>
        </v-col>
        <!--VENCEN EN 6 MESES-->
        <v-col
          v-if="contrate.cant_vencen_6 > 0"
          cols="6"
          class="d-flex align-center"
        >
          <v-avatar
            size="44"
            color="primary"
            rounded
            class="elevation-1"
          >
            <v-icon
              dark
              color="white"
              size="30"
            >
              mdi-currency-usd
            </v-icon>
          </v-avatar>
          <div class="ms-3">
            <p class="text-xs mb-0">
              {{ $t('dashboard.venceIn', { n: 6 }) }}
            </p>
            <h3 class="text-xl font-weight-semibold">
              {{ contrate.cant_vencen_6 }}
            </h3>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiAccountOutline,
  mdiCurrencyUsd,
  mdiTrendingUp,
  mdiDotsVertical,
  mdiLabelOutline,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    user: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    contrate: {
      type: Object,
    },
  },
  data() {
    return {
      icons: {
        mdiAccountOutline,
        mdiCurrencyUsd,
        mdiTrendingUp,
        mdiDotsVertical,
        mdiLabelOutline,
      },
    }
  },
  methods: {
    getSourceByOne(contrate) {
      let result = 'Directo'
      if (contrate.source.includes('hotetec')) {
        result = 'Hotetec'
      } else if (contrate.source.includes('dingus')) {
        result = 'Dingus'
      } else if (contrate.source.includes('solways')) {
        result = 'Solways'
      }

      return result
    },
    goContrate() {
      localStorage.setItem('hotels-contrate-id', this.contrate.id)
      localStorage.removeItem('contrate-hotels-show')

      this.$router.push({ name: 'hotels-contrate-update' })
    },
  },
}
</script>
